import React from 'react';
import { graphql, Link } from 'gatsby';
import ToHtml from '../components/html';
import { HelmetDatoCms } from 'gatsby-source-datocms';

import { Gimg } from '../components/gimg';
import { Container } from '../components/base';
import { SubHero } from '../layout/subHero';
import { Page } from '../types';
import styled from 'styled-components';
import { mediaDesktop, colors } from '../components/setting';
import { Marks } from '../layout/marks';
import { LinkButton } from '../components/button';

type aboutPage = {
	data: {
		datoCmsPage: Page;
		allDatoCmsMark: any;
	};
};

export default ({ data }: aboutPage) => {
	const node = data.datoCmsPage;
	// export default (data) => {
	return (
		<>
			<SubHero data={node} />
			<Container>
				<HelmetDatoCms seo={node.seoMetaTags} />
				<Content>
					<Box>
						<ToHtml tags={node.content} />
						<LinkButton as={Link} style={{ marginTop: '2.5rem' }} to="/galeria/">
							Zobacz galerie
						</LinkButton>
					</Box>

					<Gallery>
						{node.gallery.map((el: any, i: number) => (
							<GalleryItem key={i} className={'index-' + i}>
								<Gimg
									fixed={el.fixed}
									style={{
										width: '100%',
										height: '100%',
										display: 'block',
									}}
									alt={el.alt}
								/>
							</GalleryItem>
						))}
					</Gallery>
				</Content>
				<Marks data={data.allDatoCmsMark} />
			</Container>
		</>
	);
};

const Content = styled.div`
	@media ${mediaDesktop} {
		display: flex;
		width: 100%;
		position: relative;
		margin-top: 10rem;
		margin-bottom: 35rem;
	}
`;
const Gallery = styled.div`
	/* display: block; */
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	/* height: 600px; */
	margin: 5rem 0;
	@media ${mediaDesktop} {
		margin: 0;
		width: 60%;
		position: absolute;
		height: 85%;
		min-height: 55rem;
		top: -6.5rem;
		right: -1.5rem;
	}
`;
const Box = styled.div`
	@media ${mediaDesktop} {
		width: 70%;
		padding: 7rem 40% 12rem 0rem;
		background: ${colors.gray};
		position: relative;

		&::after {
			content: '';
			background: ${colors.gray};
			position: absolute;
			top: 0;
			right: 100%;
			bottom: 0;
			width: 50vh;
		}
	}
`;

const GalleryItem = styled.div`
	/* position: absolute; */

	width: 100%;
	height: 35rem;
	margin-bottom: 3rem;

	&.index-1,
	&.index-2 {
		height: 20rem;
		width: calc(50% - 1.5rem);
	}

	@media ${mediaDesktop} {
		display: block;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		padding: 1.5rem;

		&.index-1 {
			top: 100%;
			width: 60%;
			height: 45rem;
		}
		&.index-2 {
			top: 100%;
			right: 60%;
			width: 50%;
			height: 37rem;
		}
	}
`;

export const query = graphql`
	query AboutQuery($slug: String) {
		datoCmsPage(slug: { eq: $slug }) {
			title
			slug
			content
			category {
				slug
				title
			}
			img {
				fixed(width: 1920, imgixParams: { fm: "jpg", auto: "compress" }) {
					...GatsbyDatoCmsFixed
				}
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			gallery {
				alt
				url
				fixed(width: 537, imgixParams: { fm: "jpg", auto: "compress" }) {
					...GatsbyDatoCmsFixed
				}
			}
		}
		allDatoCmsMark {
			nodes {
				name
				img {
					fixed(width: 232, imgixParams: { fm: "jpg", auto: "compress" }) {
						...GatsbyDatoCmsFixed
					}
					url
				}
			}
		}
	}
`;
